import { computed, ComputedRef, ref } from '@vue/composition-api';
import { NavigationClient } from '@azure/msal-browser';
import { Auth, MaybeAccount } from '@/services/auth';

export const initialized = ref(false);
export const account = ref<MaybeAccount>(null);
export const error = ref<string>();

export default function useAuth(): {
  initialized: ComputedRef<boolean>;
  account: ComputedRef<MaybeAccount>;
  error: ComputedRef<string | undefined>;
  initialize: (client?: NavigationClient) => Promise<MaybeAccount>;
  login: () => Promise<void>;
  logout: () => Promise<void>;
} {
  async function initialize(client?: NavigationClient) {
    if (initialized.value === true) {
      return account.value;
    }
    return Auth.initialize(client).then(data => {
      account.value = data;
      return data;
    });
  }

  async function login() {
    error.value = '';
    return Auth.login();
  }

  async function logout() {
    return Auth.logout().then(() => {
      account.value = null;
    });
  }

  return {
    initialized: computed(() => initialized.value),
    account: computed(() => account.value),
    error: computed(() => error.value),
    initialize,
    login,
    logout,
  };
}
